import React from 'react'
import styled from 'styled-components'
import { graphql, withPrefix } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Reference from '../shared/reference'
import * as defaults from '../shared/markdown-defaults'
import Page, { Section } from '../shared/page-commons'
import { MDBanner } from '../shared/banner.js'
import Tag, { TagGroup } from '../shared/tag.js'
import ResearchArea, { RESEARCH_LOOKUP } from '../shared/research-area.js'
import { dateFormatter } from '../shared/formatters.js'
import Title from '../shared/section-header'
import { FilterButton } from '../shared/filter.js'
import { screen } from '../shared/breakpoints'
import Link from '../shared/link'
import MetaData from '../shared/meta-data'

const Cover = styled.img`
  font-size: 0.816rem;
  font-weight: bold;
  border: 4px solid var(--surface);
  border-radius: 2px;
  width: 100%;
  box-shadow: 0 0.3px 0.5px rgba(0, 0, 0, 0.048),
    0 0.9px 1.8px rgba(0, 0, 0, 0.072), 0 4px 8px rgba(0, 0, 0, 0.12);
`

const Divider = styled(defaults.hr)`
  margin-top: 50px;
  margin-bottom: 0;

  @media ${screen.md} {
    margin-top: 80px;
    margin-bottom: 0;
  }
`

const HeadWrapper = styled.div`
  @media ${screen.md} {
    display: flex;
    align-items: center;
  }
`

const ImageLink = styled(Link)`
  display: block;
  max-width: 275px;
  margin: 50px auto;

  @media ${screen.md} {
    margin: 0;
    max-width: auto;
    width: calc(41.6666666% - 20px);
  }
`

const TextGroup = styled.div`
  @media ${screen.md} {
    margin: 35px 0 35px 50px;
    width: calc(58.3333333% - 20px);
  }
`

export default function ActaFuturaLayout(props) {
  // look at the props passed to this component in the console
  const { frontmatter, fields } = props.data.mdx

  return (
    <Page bgColor="surface">
      <MetaData
        isBlogPost
        title={frontmatter.title}
        description={frontmatter.headline}
        image={frontmatter.image_src}
        slug={fields.slug}
        date={frontmatter.date}
        category={frontmatter.categories
          ?.map((key) => RESEARCH_LOOKUP[key])
          .join(', ')}
      />
      <MDBanner frontmatter={frontmatter} />
      <Section css="padding-bottom: 0;" textColumn>
        <TagGroup>
          {frontmatter.categories?.map((category) => (
            <Tag size="lg" key={category}>
              <ResearchArea>{category}</ResearchArea>
            </Tag>
          ))}
          {frontmatter.date && (
            <Tag size="lg">
              {dateFormatter.format(new Date(frontmatter.date))}
            </Tag>
          )}
        </TagGroup>
        <Title>{frontmatter.title}</Title>
        <HeadWrapper>
          <ImageLink
            document={frontmatter.document_url}
            href={frontmatter.external_url}
          >
            <Cover src={withPrefix(frontmatter.image_src)} />
          </ImageLink>
          <TextGroup>
            <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
            {frontmatter.document_url && (
              <FilterButton
                css="margin-top: 20px;"
                as={Link}
                document={frontmatter.document_url}
                href={frontmatter.external_url}
              >
                Download
              </FilterButton>
            )}
          </TextGroup>
        </HeadWrapper>
        {frontmatter.bibliography && <Divider />}
      </Section>
      {frontmatter.bibliography && (
        <Section textColumn>
          <defaults.h2 css="margin-top: 0;">Articles</defaults.h2>
          <div role="list">
            {frontmatter.bibliography.childrenReference.map((reference) => {
              return <Reference reference={reference} key={reference.key} />
            })}
          </div>
        </Section>
      )}
    </Page>
  )
}

export const query = graphql`
  query ActaFuturaQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        categories
        image_src
        headline
        banner
        document_url
        external_url
        banner_caption
        date
        bibliography {
          childrenReference {
            authors
            title
            year
            type
            url
            key
            volume
            rawBibtex
            pages
            journal
          }
        }
      }
    }
  }
`
